<template>
  <v-app id="main-app">
    <span class="d-flex justify-end align-center ma-6">
      <span style="width: 8%">
        <v-select
            class=""
            v-model="languageSelect"
            :items="langs"
            item-text="name"
            item-value="id"
            menu-props="auto"
        >
          <template #prepend>
            <div class="mt-n1">
            <country-flag :country=" languageSelect === 'en' ? 'usa' : 'esp' "/>
            </div>
          </template>
        </v-select>
      </span>
    </span>
    <span class="my-auto">
      <v-container>
        <span class="d-flex justify-center align-center my-6">
          <img
              :src="logo"
          />
          <h2 style="margin-left: 10px; font-weight: 400;">{{ $t('product-name') }}</h2>
        </span>
        <v-card style="margin-bottom: 48px; margin-top: 44px" color="white" class="mx-auto rounded" width="360px">
          <v-btn
              text
              v-if="showForm === 2"
              @click="showform"
              class="mt-5 title-forgot-password"
          >
            <v-icon class="primary--text mr-3">mdi-arrow-left-bottom</v-icon>
            <span style="font-size: 14px" class="primary--text">{{ $t('Back to Sign in Page') }}</span>
          </v-btn>
          <v-row>
            <v-row
                v-if="showForm ===1"
                class="font-bold text-center pt-2 mb-4 d-flex justify-center align-center main_black--text title-login"
                style="font-size: 20px !important;"
            >
              {{ $t('welcomeToVitalCheckUps') }} {{ $t('product-name') }}
            </v-row>
            <v-row
                v-else
                class="font-bold text-center d-flex justify-center align-center main_black--text title-login mt-9  mb-4"
            >
              <span style="font-size: 20px"> {{ $t('forgot') }} {{ $t('password') }}</span>
            </v-row>
          </v-row>
          <span
              class="text-center main_black--text"
              style="font-size: 16px !important; margin: 0 8px; padding: 4px; opacity: 0.7"
          >
            {{ $t('moto') }}
          </span>
          <alert-list-auth />
          <v-card-text class="text-center pt-2">
            <v-form ref="form1" v-if="showForm === 1" @submit.prevent="login">
              <v-text-field
                  class="mt-1"
                  dense
                  hide-details
                  v-model="email"
                  ref="email"
                  :label="$t('email')"
                  name="email"
                  type="email"
                  outlined
                  :rules="[rules.emailRequired, rules.email]"
                  @keyup.enter="clickButton"
              />
              <v-text-field
                  class="my-4"
                  dense
                  hide-details
                  v-model="password"
                  :label="$t('password')"
                  name="password"
                  outlined
                  :rules="[rules.passwordRequired]"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :autocomplete="showPassword ? 'off' : 'current-password'"
                  :type="showPassword ? 'text' : 'password'"
                  @click:append="showPassword = !showPassword"
                  @keyup.enter="clickButton"
              />
            </v-form>
            <v-form
                ref="form2"
                v-if="showForm === 2"
                @submit.prevent="resetPassword"
                style="margin-bottom: 3%"
            >
              <v-input v-if="!emailSent" class="main-black--text receive-email-text" style="font-size: 14px; text-align: center;">
                {{ $t('enterYourEmailToRecieveResetPasswordLink') }}
              </v-input>
              <span v-else style="font-size: 14px" class="main_black--text receive-email-text">
                {{ $t('weSentAnResetLink') }}
                <b class="ml-2">{{ this.email }}</b>
              </span>
              <v-text-field
                  dense
                  hide-details
                  v-if="!emailSent"
                  v-model="email"
                  label="Email"
                  name="email"
                  type="email"
                  outlined
                  :rules="[rules.emailRequired, rules.email]"
              />
            </v-form>
            <v-btn
                v-if="!emailSent"
                color="primary"
                style="text-transform: lowercase; border-radius: 4px; font-size: 14px; display: flex; width: 100%; height: 32px;"
                :loading="loading"
                :disabled="loading"
                @click="loader = 'loading'"
            >
              <span style="text-transform: none;">{{ action }}</span>
            </v-btn>
            <v-btn
                text
                v-if="showForm !== 2"
                @click="showForm = 2"
                style="border-radius: 4px; font-size: 14px;"
                class=" primary--text mt-3 title-forgot-password"
            >
              {{ $t('forgot') }} {{ $t('password') }}?
            </v-btn>
          </v-card-text>
          <v-spacer />
          <!--        <v-footer color="transparent" class="px-10" absolute padless>-->
          <!--        </v-footer>-->
        </v-card>
        <span v-if="showForm===1">
          <v-row dense>
            <v-col class="text-center  main_black--text ma-0 pa-0" cols="12">
              <span style="font-size: 14px; opacity: 0.7">
                {{ $t('signing-in-to-this-webapp') }}
              </span>
              <span
                  style="font-size: 14px; cursor: pointer;"
                  class="primary--text"
                  @click="redirectToWebsiteTerms"
              >
                {{ $t('website-terms-of-use') }}
              </span>
            </v-col>
          </v-row>
          <v-row dense class="ma-0 pa-0 ">
            <v-col class="text-center  main_black--text mt-0 pt-0" cols="12">
              <span style="font-size: 14px; opacity: 0.7">
                {{ $t('acknowledge') }}
              </span>
              <span
                  class="primary--text"
                  style="font-size: 14px; cursor: pointer;"
                  @click="redirectToPrivacyTerms"
              >
                {{ $t('privacy-policy') }}
              </span>
            </v-col>
          </v-row>
          <span class="primary--text" style="font-size: 14px; cursor:pointer;" @click="redirectToOldUi">
            {{$t('redirect-old')}}
          </span>
        </span>
      </v-container>
    </span>
  </v-app>
</template>

<script>
import AlertListAuth from '../Client/components/AlertListAuth.vue';
import logoBridge from '../../../public/logo_bridge.png';
import Login from '../../data/models/Login';
import { mapGetters, mapState } from 'vuex';
import { logo } from '@/assets';
// import flag from '@growthbunker/vueflags';

// import CountryFlag from 'vue-country-flag';

export default {
	components: {
		AlertListAuth,
		// flag,
	},
	data () {
		return {
      logo: logo,
			loader: null,
			loading: false,
			showForm: 1,
			showPassword: false,
			logo_bridge: logoBridge,
			languageSelect: 'en',
			langs: [
				{ id: 'en', name: 'EN' },
				{ id: 'es', name: 'ES' },
				// { id: 'mk', name: 'English' },
			],
			rules: {
				emailRequired: (value) => !!value || 'Enter your email address',
				email: (value) => {
					const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
					return pattern.test(value) || 'Enter a valid e-mail address';
				},
				passwordRequired: (value) => !!value || 'Enter your password',
			},
			email: '',
			password: '',
			emailSent: false,
		};
	},
	computed: {
		...mapGetters({ delay: 'authentication/getDelay' }),
		...mapState({
			userDetails: (state) => state.authentication.userData,
		}),
		action () {
			switch (this.showForm) {
			case 1:
				return this.$t('signin');
			case 2:
				return this.$t('submit');
			default:
				return '';
			}
		},
	},
	watch: {
		languageSelect (val) {
			this.$cookies.set('language', val, '6m');
			this.$i18n.locale = this.$cookies.get('language');
		},
		async loader () {
			if (this.loader !== null) {
				const l = this.loader;
				this[l] = !this[l];
				await this.clickButton()
					.then(() => {
						this[l] = false;
					})
					.catch(() => {
						this[l] = false;
					});
				this.loader = null;
			}
		},
	},
	mounted () {
		if (this.$store.getters['authentication/hasStoredSession']) {
			this.$store.dispatch('authentication/doLogout');
			this.$store.commit('alerts/clear');
			localStorage.clear();
		}
		this.$i18n.locale = 'en';
	},
	methods: {
		language (val) {
			this.$cookies.set('language', val, '6m');
			this.$i18n.locale = this.$cookies.get('language');
		},
		redirectToWebsiteTerms () {
			window.open(
				'https://www.vitalcheckups.com/vitakcheckups_website_terms_of_use_rw_4_28_2022.html',
				'_blank',
			);
		},
		redirectToPrivacyTerms () {
			window.open(
				'https://www.vitalcheckups.com/vitalcheckups_website_privacy_policy_rw__4_28_2022.html',
				'_blank',
			);
		},
    redirectToOldUi () {
      window.open(
          'https://devapp.vitalcheckups.com',
          '_blank',
      );
    },
		showform () {
			this.showForm = 1;
			this.emailSent = false;
		},
		async login () {
			const login = new Login({
				username: this.email,
				password: this.password,
			});
			await this.$store
				.dispatch('authentication/doLogin', login)
				.then(async () => {
					switch (this.$store.getters['authentication/getRole']) {
					case 'SYSTEM_ADMIN':
						this.$router.push('/organizations');
						break;
					case 'ORGANIZATION_ADMIN':
						this.$router.push('/organizations');
						break;
					case 'HOSPITAL_ADMIN':
						this.$router.push('/medicalstaff');
						break;
					default:
						this.$router.push('/dashboard');
						await this.$store.dispatch('setupWebSockets');
						break;
					}
					var tempcookie;
					if (this.$cookies.get('language') === null) {
						switch (this.userDetails.language) {
						case 'ENG':
							this.$cookies.set('language', 'en', '6m');
							break;
						case 'ESP':
							this.$cookies.set('language', 'es', '6m');
							break;
						}
						this.$i18n.locale = this.$cookies.get('language');
					} else {
						switch (this.$cookies.get('language')) {
						case 'en':
							tempcookie = 'ENG';
							break;
						case 'es':
							tempcookie = 'ESP';
							break;
						}
						this.$i18n.locale = this.$cookies.get('language');
						if (tempcookie !== this.userDetails.language) {
							var body = {
								Language: tempcookie,
							};
							this.$store.dispatch('users/changemyLanguage', body);
						}
					}
				})
				.catch((err) => {
					this.$store.commit('alerts/add', {
						auth: true,
						type: 'error',
						color: '#D30202',
						message: err.response.data.msg,
					});
					this.$store.dispatch('alerts/clearWithDelay', this.delay);
				});
		},
		async resetPassword () {
			const body = {
				email: this.email,
			};
			await this.$store
				.dispatch('users/sendValidationLinkByEmail', body)
				.then((res) => {
					if (res.resFlag) {
						this.emailSent = true;
					}
				})
				.catch(() => {
					this.$store.commit('alerts/add', {
						auth: true,
						type: 'error',
						color: '#D30202',
						message: 'Could not sent validation link',
					});
					this.$store.dispatch('alerts/clearWithDelay', this.delay);
				});
		},
		clickButton () {
			switch (this.showForm) {
			case 1:
				return this.login();
			case 2:
				return this.resetPassword();
			}
		},
	},
};
</script>
<style scoped>
#main-app {
  background: #F9F8FB;
}
</style>
